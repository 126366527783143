import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import { getPreviewArticleOrCategorySlugs } from '../utilities';
import Header from '../Header/index';
import Footer from '../footer';
import Layout from '../layout';
import Seo from '../seo';
import { getPreviewPage } from '../../state/app';
import { Image } from '../BlockLibrary/Common/Image';
import Breadcrumbs from '../BlockLibrary/Healthzone/Breadcrumbs';

class Category extends Component {
     constructor(props) {
          super(props);

          this.state = {
               breedClicked: false,
               articlePerPage: 6,
               recentArticles: 12
          }
     }

     componentDidMount() {
          document.addEventListener('mousedown', this.handleClickOutside);
     }

     componentWillUnmount() {
          document.removeEventListener('mousedown', this.handleClickOutside);
     }

     toggleBreed = (e) => {
          e.stopPropagation();
          this.setState({ breedClicked: !this.state.breedClicked })
     }

     loadMoreArticle = () => {
          this.setState({ recentArticles: this.state.recentArticles + this.state.articlePerPage })
     }

     showLessArticle = () => {
          this.setState({ recentArticles: this.state.articlePerPage })
     }

     setWrapperRef = (node) => {
          this.wrapperRef = node;
     }

     handleClickOutside = (event) => {
          if (this.state.breedClicked && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
               this.setState({ breedClicked: false })
          }
     }

     render() {
          const { category, allArticles, childCategories, isLoading } = this.props;
          let backgroundImageUrl = category.backgroundImage ? `url(${category.backgroundImage.fields.file.url})` : null;

          // Get breeds articles from allArticles which come from the same category
          let allBreedArticles = allArticles.filter((article) => {
               return article.fields.breedName;
          });

          let allRecentArticles = allArticles.filter(article => {
               return !article.fields.breedName;
          });

          if (allRecentArticles.length >= 2) {
               allRecentArticles = orderBy(allRecentArticles, 'sys.createdAt', 'desc');
          }

          let allBreedsTemp = groupBy(allBreedArticles, article => {
               return article.fields.breedName.trim().charAt(0).toUpperCase();
          })

          const allBreeds = {};
          Object.keys(allBreedsTemp).sort().forEach(key => {
               allBreeds[key] = allBreedsTemp[key];
          });

          let recentArticlesToShow = allRecentArticles ? allRecentArticles.slice(0, this.state.recentArticles) : [];

          return (
               <>
                    <Layout>
                         <Seo
                              title={category.fields.seoTitleTag}
                              canonical={category.fields.seoCanonicalUrl}
                              description={category.fields.seoMetaDescription}
                              robots={category.fields.robotsDirective}
                              image={category.fields.socialImage ? category.fields.socialImage : category.fields.featuredImage}
                         />
                         {
                              isLoading && (
                                   <div className="page-loading">Loading ...</div>
                              )
                         }
                         <Header header={category.fields.header}></Header>
                         <Breadcrumbs category={category}></Breadcrumbs>
                         <section
                              className={`category-intro ${backgroundImageUrl ? 'has-background' : ''}`}
                              style={{ backgroundImage: backgroundImageUrl }}
                         >
                              <div className="container">
                                   <div className="row">
                                        <div className="col-lg-6">
                                             <h1>{category.fields.h1 ? category.fields.h1 : category.fields.categoryName}</h1>
                                             {category.fields.shortDescription && <p>{category.fields.shortDescription}</p>}
                                             {childCategories.length > 0 && (
                                                  <div className="sub-categories">
                                                       {
                                                            childCategories.map((childCategory, i) => {
                                                                 let slugs = '/healthzone/' + getPreviewArticleOrCategorySlugs(childCategory).reverse().join('/') + '/';

                                                                 return (
                                                                      <React.Fragment key={i}>
                                                                           {childCategory.fields.categoryName && <a href={slugs} className="btn-healthzone">{childCategory.fields.categoryName}</a>}
                                                                      </React.Fragment>
                                                                 )
                                                            })
                                                       }
                                                  </div>
                                             )}
                                             <div className="breed-selector-label">
                                                  {category.fields.categoryName && <p>See all {category.fields.categoryName.toLowerCase()}</p>}
                                             </div>
                                             {
                                                  category.fields.isBreeds && (
                                                       <div className="breed-selector" ref={this.setWrapperRef}>
                                                            <button className="breed-selector-button" onClick={this.toggleBreed}>
                                                                 Select one from the list
                                                            <i className={`${this.state.breedClicked ? 'far fa-chevron-up' : 'far fa-chevron-down'}`}></i>
                                                            </button>
                                                            <div
                                                                 className={`breed-selector-box${this.state.breedClicked ? ' bs-anim-on bs-anim-off' : ''}`}
                                                            >
                                                                 {allBreedArticles.length > 0 ? (
                                                                      <>
                                                                           <div className="breed-selector-list" >
                                                                                {
                                                                                     Object.entries(allBreeds).map(([alphabet, breedArticles]) => {
                                                                                          return (
                                                                                               <React.Fragment key={alphabet}>
                                                                                                    <h2 id={alphabet} className="alphabet-title">{alphabet}</h2>
                                                                                                    <ul>
                                                                                                         {
                                                                                                              breedArticles.map(({ fields: breedArticle }, i) => {
                                                                                                                   let slugs = '/healthzone/' + getPreviewArticleOrCategorySlugs(breedArticle.category).reverse().join('/') + '/' + breedArticle.slug + '/';

                                                                                                                   return (
                                                                                                                        <li key={i}>
                                                                                                                             <a href={slugs}>{breedArticle.breedName}</a>
                                                                                                                        </li>
                                                                                                                   )
                                                                                                              })
                                                                                                         }
                                                                                                    </ul>
                                                                                               </React.Fragment>
                                                                                          )
                                                                                     })
                                                                                }
                                                                           </div>
                                                                           <div className="breed-selector-alpha">
                                                                                <ul>
                                                                                     {
                                                                                          Object.entries(allBreeds).map(([key, value]) => (
                                                                                               <li key={key}>
                                                                                                    <a href={`#${key}`}>{key}</a>
                                                                                               </li>
                                                                                          ))
                                                                                     }
                                                                                </ul>
                                                                           </div>
                                                                      </>
                                                                 ) : (
                                                                           <p>No articles</p>
                                                                      )}
                                                            </div>
                                                       </div>
                                                  )
                                             }
                                        </div>
                                   </div>
                              </div>
                         </section>
                         {
                              recentArticlesToShow.length > 0 && (
                                   <section className={`recent-articles`}>
                                        <div className="container">
                                             <div className="row articles-wrap">
                                                  {
                                                       recentArticlesToShow.map(({ fields: article }, i) => {
                                                            let slugs = '/healthzone/' + getPreviewArticleOrCategorySlugs(article.category).reverse().join('/') + '/' + article.slug + '/';

                                                            return (
                                                                 <div className="col-sm-6 col-md-4 article-block" key={i}>
                                                                      <div className="article-detail">
                                                                           {article.featuredImage && (
                                                                                <a href={slugs}>
                                                                                     <div className="image-wrap"><Image image={article.featuredImage} altOverride={(article.contentName) ? article.contentName : ''} /></div>
                                                                                </a>
                                                                           )}
                                                                           <div className="content">
                                                                                {article.contentName && (
                                                                                     <a aria-label={article.contentName} href={slugs}>
                                                                                          <h3>{article.contentName}</h3>
                                                                                     </a>
                                                                                )}
                                                                                {article.shortDescription && <p>{article.shortDescription}</p>}
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            )
                                                       })
                                                  }
                                             </div>
                                             {
                                                  allRecentArticles.length > 12 && (
                                                       this.state.recentArticles >= allRecentArticles.length ?
                                                            (<button className="button-white" onClick={this.showLessArticle}>
                                                                 Show less articles
                                                            </button>)
                                                            :
                                                            (<button className="button-white" onClick={this.loadMoreArticle}>
                                                                 Load more articles
                                                            </button>)
                                                  )
                                             }
                                        </div>
                                   </section>
                              )
                         }
                         <Footer footer={category.fields.footer}></Footer>
                    </Layout>
               </>
          )
     }
}

Category.propTypes = {
     category: PropTypes.object,
}

function mapStateToProps(state) {
     return {
          category: getPreviewPage(state),
     };
}

export default connect(mapStateToProps)(Category);
