import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import orderBy from 'lodash/orderBy';
import Header from '../Header/index';
import Footer from '../footer';
import Layout from '../layout';
import Seo from '../seo';
import { getPreviewPage } from '../../state/app';
import { Image } from '../BlockLibrary/Common/Image'
import Breadcrumbs from '../BlockLibrary/Healthzone/Breadcrumbs';

class LastCategory extends Component {
     constructor(props) {
          super(props);

          this.state = {
               articlePerPage: 6,
               recentArticles: 12,
          }
     }

     loadMoreArticle = () => {
          this.setState({ recentArticles: this.state.recentArticles + this.state.articlePerPage })
     }

     showLessArticle = () => {
          this.setState({ recentArticles: this.state.articlePerPage })
     }

     render() {
          const { category, allArticles, isLoading } = this.props;

          // Remove infographic articles
          const notInfographicArticles = allArticles.filter(article => {
               return !article.fields.isInfographic
          })

          const seoDescription = category.fields.seoMetaDescription != null ? category.fields.seoMetaDescription : '';
          let recentArticlesToShow = [];

          if (notInfographicArticles.length >= 2) {
               const articlesAfterSorting = orderBy(notInfographicArticles, 'sys.createdAt', 'desc');
               var firstArticle = articlesAfterSorting[0];
               recentArticlesToShow = articlesAfterSorting.slice(1, this.state.recentArticles + 1);
          } else {
               var firstArticle = notInfographicArticles[0];
          }

          const firstArticleSlugs = `/article-preview/${firstArticle.sys.id}`;

          return (
               <>
                    <Layout>
                         <Seo
                              title={category.fields.seoTitleTag}
                              canonical={category.fields.seoCanonicalUrl}
                              description={category.fields.seoMetaDescription}
                              robots={category.fields.robotsDirective}
                              image={category.fields.socialImage ? category.fields.socialImage : category.fields.featuredImage}
                         />
                         {
                              isLoading && (
                                   <div className="page-loading">Loading ...</div>
                              )
                         }
                         <Header header={category.fields.header}></Header>
                         <Breadcrumbs category={category}></Breadcrumbs>
                         <section className={`category-intro`}>
                              <div className="container">
                                   <div className="row">
                                        <div className="col-12">
                                             <h1>{category.fields.h1 ? category.fields.h1 : category.fields.categoryName}</h1>
                                        </div>
                                   </div>
                                   <div className="row">
                                        <div className="col-lg-8 category-col-left">
                                             {
                                                  firstArticle && (
                                                       <div className="category-content">
                                                            {firstArticle.fields.featuredImage && (
                                                                 <div className="healthzone-intro-image">
                                                                      <a href={firstArticleSlugs} tabIndex={-1}>
                                                                           <Image image={firstArticle.fields.featuredImage} />
                                                                      </a>
                                                                 </div>
                                                            )}
                                                            <div className="category-article-description">
                                                                 {firstArticle.fields.contentName && (
                                                                      <a aria-label={firstArticle.fields.contentName} href={firstArticleSlugs}>
                                                                           <h2>{firstArticle.fields.contentName}</h2>
                                                                      </a>
                                                                 )}
                                                                 {firstArticle.fields.shortDescription && <p>{firstArticle.fields.shortDescription}</p>}
                                                            </div>
                                                       </div>
                                                  )
                                             }
                                        </div>
                                        <div className="col-lg-4 category-col-right healthzone-subcribe-form">
                                             {/* <form>
                                                  <h4>Get Informative Articles Delivered Weekly</h4>
                                                  <div className="form-group">
                                                       <label htmlFor="firstName">First name</label>
                                                       <input
                                                            id="firstName"
                                                            type="text"
                                                            className="form-control"
                                                            id="firstName"
                                                            value={this.state.firstName}
                                                            onChange={this.onFirstNameChanged}
                                                       />
                                                  </div>
                                                  <div className="form-group">
                                                       <label htmlFor="emailAddress">Enter email address</label>
                                                       <input
                                                            id="emailAddress"
                                                            type="email"
                                                            className="form-control"
                                                            id="emailAddress"
                                                            value={this.state.emailAddress}
                                                            onChange={this.onEmailAddressChanged}
                                                       />
                                                  </div>
                                                  <p>By subscribing, you agree to our Terms of Use and Privacy Policy.</p>
                                                  <button type="submit" className="subcribe-btn">Subscribe</button>
                                             </form> */}
                                        </div>
                                   </div>
                              </div>
                         </section>
                         {
                              recentArticlesToShow.length > 0 && (
                                   <section className={`recent-articles`}>
                                        <div className="container">
                                             <div className="row articles-wrap">
                                                  {
                                                       recentArticlesToShow.map((article, i) => {
                                                            let slugs = `/article-preview/${article.sys.id}`;

                                                            return (
                                                                 <div className="col-sm-6 col-md-4 article-block" key={i}>
                                                                      <div className="article-detail">
                                                                           {article.fields.featuredImage && (
                                                                                <a href={slugs} tabIndex={-1}>
                                                                                     <div className="image-wrap"><Image image={article.fields.featuredImage} altOverride={(article.fields.contentName) ? article.fields.contentName : ''} /></div>
                                                                                </a>
                                                                           )}
                                                                           <div className="content">
                                                                                {article.fields.contentName && (
                                                                                     <a aria-label={article.fields.contentName} href={slugs}>
                                                                                          <h3>{article.fields.contentName}</h3>
                                                                                     </a>
                                                                                )}
                                                                                {article.fields.shortDescription && <p>{article.fields.shortDescription}</p>}
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            )
                                                       })
                                                  }
                                             </div>
                                             {
                                                  (notInfographicArticles.length - 1) > 12 && (
                                                       this.state.recentArticles >= notInfographicArticles.length ?
                                                            (<button className="button-white" onClick={this.showLessArticle}>Show less articles</button>)
                                                            :
                                                            (<button className="button-white" onClick={this.loadMoreArticle}>Load more articles</button>)
                                                  )
                                             }
                                        </div>
                                   </section>
                              )
                         }
                         <Footer footer={category.fields.footer}></Footer>
                    </Layout>
               </>
          )
     }
}

LastCategory.propTypes = {
     category: PropTypes.object,
}

function mapStateToProps(state) {
     return {
          category: getPreviewPage(state)
     };
}

export default connect(mapStateToProps)(LastCategory);
