import React from 'react';
import { connect } from "react-redux";
import Category from '../components/PreviewLibrary/Category';
import LastCategory from '../components/PreviewLibrary/LastCategory';
import { getPreviewPage } from '../state/app';
import { setOmCode, setPhoneLocalStorage } from '../components/utilities';
import Pusher from 'pusher-js';

const contentful = require("contentful");

const client = contentful.createClient({
     space: process.env.CONTENTFUL_SPACE_ID,
     accessToken: process.env.CONTENTFUL_PREVIEW_TOKEN,
     host: 'preview.contentful.com',
     environment: process.env.GATSBY_CONTENTFUL_PREVIEW_ENVIRONMENT
});

class CategoryPreviewTemplate extends React.Component {
     constructor(props) {
          super(props);

          this.state = {
               loading: true,
               sysId: this.props['*'],
               allArticles: [],
               childCategories: [],
          }
     }

     findParentCategory = (parentCategory) => {
          if (typeof parentCategory == 'undefined') { return '' };

          for (let [key, value] of Object.entries(this.state.allArticles)) {
               if (key == parentCategory.sys.id) {
                    return parentCategory.sys.id;
               }
          }

          return this.findParentCategory(parentCategory.fields.parentCategory);
     }

     getArticlesOfChildCategory = (category) => {
          return client.getEntries({
               'links_to_entry': category.sys.id,
               'include': 10
          })
               .then((response) => {
                    let childCategories = [];
                    let articles = [];

                    articles = response.items.filter(item => {
                         return item.sys.contentType.sys.id == 'hzArticle';
                    });

                    childCategories = response.items.filter(item => {
                         return item.sys.contentType.sys.id == 'hzCategory';
                    })

                    if (childCategories.length > 0) {
                         childCategories.forEach((childCategory) => {
                              this.getChildCategoryArticles(childCategory).then(childCategoryArticles => {
                                   const parentCategoryId = this.findParentCategory(childCategory.fields.parentCategory);
                                   if (parentCategoryId) {
                                        const newArticles = this.state.allArticles[parentCategoryId].concat(childCategoryArticles);

                                        this.setState({ allArticles: this.state.allArticles.concat(newArticles) })
                                        // Put object name in square brackets if you want use variable for object name
                                        // this.setState({ allArticles: { ...this.state.allArticles, [parentCategoryId]: newArticles } })
                                   }
                              });
                         })
                    }

                    return articles;
               })
               .catch(console.error)
     }

     getChildCategoryArticles = (category) => {
          return Promise.resolve(this.getArticlesOfChildCategory(category))
     }

     componentDidMount() {
          const _this = this;
          const brower = typeof window !== `undefined`;
          _this.setState({ ..._this.state, loading: true });
          client.getEntries({
               'links_to_entry': _this.state.sysId,
               'include': 10
          })
               .then((response) => {
                    let childCategories = [];
                    childCategories = response.items.filter(item => {
                         return item.sys.contentType.sys.id == 'hzCategory';
                    })

                    var allArticles = response.items.filter(item => {
                         return item.sys.contentType.sys.id == 'hzArticle';
                    });

                    if (childCategories.length > 0) {
                         childCategories.forEach(category => {
                              // this.getChildCategoryArticles(category)
                              this.getChildCategoryArticles(category).then(childCategoryArticles => {
                                   this.setState({ allArticles: this.state.allArticles.concat(childCategoryArticles) })
                              })
                         })
                    }

                    this.setState({ allArticles, childCategories })
               })
               .catch(console.error)

          client
               .getEntries({
                    'sys.id': _this.state.sysId,
                    'include': 10
               })
               .then(entries => {
                    setTimeout(function () {
                         _this.setState({ ..._this.state, loading: false });
                    }, 1000)
                    if (entries.total > 0) {
                         _this.props.setPreviewIncludes(entries.includes);
                         _this.props.setPreviewPage(entries.items[0]);
                         if (brower) {
                              client
                                   .getEntry('wChsWYdrCJ5doI8QdVkBk')
                                   .then(res => {
                                        var om = entries.items[0].fields.trackingCode != null ? entries.items[0].fields.trackingCode : res.fields.trackingCode;
                                        var phone = entries.items[0].fields.specificPhone;
                                        setOmCode(om, _this.props.location.href);
                                        setPhoneLocalStorage(phone, _this.props.location.href);

                                        var getOmValue = localStorage.getItem("Petinsurance_OM");
                                        var getPhoneValue = localStorage.getItem("Petinsurance_TrackingPhone");
                                        _this.props.setTrackingCode(getOmValue);
                                        _this.props.setTrackingPhone(getPhoneValue);

                                   }).catch(err => {
                                        console.log(err)
                                   });
                         }
                    }
               }).catch(err => {
                    // console.log(err)
               });

          Pusher.logToConsole = true;

          var pusher = new Pusher('6e16fb2a37840ec116dc', {
               cluster: 'mt1',
               forceTLS: true
          });

          var channel = pusher.subscribe('my-channel');
          channel.bind('pageReload', function (data) {
               _this.setState({ ..._this.state, loading: true });
               client
                    .getEntries({
                         'sys.id': _this.state.sysId,
                         'include': 10
                    })
                    .then(entries => {
                         setTimeout(function () {
                              _this.setState({ ..._this.state, loading: false });
                         }, 1000)
                         if (entries.total > 0) {
                              _this.props.setPreviewIncludes(entries.includes);
                              _this.props.setPreviewPage(entries.items[0]);
                              if (brower) {
                                   client
                                        .getEntry('wChsWYdrCJ5doI8QdVkBk')
                                        .then(res => {
                                             var om = entries.items[0].fields.trackingCode != null ? entries.items[0].fields.trackingCode : res.fields.trackingCode;
                                             var phone = entries.items[0].fields.specificPhone;
                                             setOmCode(om, _this.props.location.href);
                                             setPhoneLocalStorage(phone, _this.props.location.href);

                                             var getOmValue = localStorage.getItem("Petinsurance_OM");
                                             var getPhoneValue = localStorage.getItem("Petinsurance_TrackingPhone");
                                             _this.props.setTrackingCode(getOmValue);
                                             _this.props.setTrackingPhone(getPhoneValue);

                                        }).catch(err => {
                                             console.log(err)
                                        });
                              }
                         }
                    }).catch(err => {
                    });
          });
     }

     render() {
          return (
               <>
                    {
                         this.props.previewPage != null && (
                              this.state.childCategories.length > 0 ? (
                                   // If this.state.allArticles is not empty, then rendering Category Component
                                   this.state.allArticles.length > 0 && (
                                        // Category has child categories
                                        <Category
                                             isLoading={this.state.loading}
                                             allArticles={this.state.allArticles}
                                             childCategories={this.state.childCategories}>
                                        </Category>
                                   )
                              ) : (
                                        (this.state.allArticles.length > 0 && (
                                             // Category doesn't have any child categories
                                             <LastCategory
                                                  isLoading={this.state.loading}
                                                  allArticles={this.state.allArticles}>
                                             </LastCategory>
                                        ))
                                   )
                         )
                    }
               </>
          )
     }
}

const mapStateToProps = (state) => {
     return {
          previewPage: getPreviewPage(state)
     }
}

const mapDispatchToProps = dispatch => {
     return {
          setPreviewIncludes: (includes) => dispatch({ type: `PREVIEW_STATE_INCLUDES`, payload: includes }),
          setPreviewPage: (page) => dispatch({ type: `PREVIEW_STATE_PAGE`, payload: page }),
          setTrackingCode: (trackingCode) => dispatch({ type: `TRACKING_CODE`, payload: trackingCode }),
          setTrackingPhone: (trackingPhone) => dispatch({ type: `TRACKING_PHONE`, payload: trackingPhone }),
     }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPreviewTemplate);